import { locale } from 'bv-i18n';

// We're getting the date in the format "2020-01-14T09:04:16.633+0000" which is not
// supported  by Safari because the time offset is not recognized, with this function
// we convert the previous to "2020-01-14T09:04:16.633+00:00" which is now ok
const formattedDate = (date) => {
  try {
    const splittedDate = date.split('+');
    return `${splittedDate[0]}+${splittedDate[1].slice(0, 2)}:${splittedDate[1].slice(2, 4)}`;
  } catch (e) {
    return date;
  }
};
// ------------------------------------------------------------------------------

export default (message) => ({
  ...message,
  text: message.previewComplete ? message.preview : null,
  deliveryDate: new Date(formattedDate(message.deliveryDate)).toLocaleDateString(locale()),
});
