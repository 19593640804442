import { connect } from 'react-redux';
import { getEditing } from 'UserInboxModal/selectors';

import {
  modifyByIds,
  toggleSelected,
  toggleExpanded,
  fetchMessage,
} from 'UserInboxModal/duck';

import MessageContainer from './message_container';

const mapStateToProps = (state) => ({
  editing: getEditing(state),
});

const mapDispatchToProps = (dispatch, props) => {
  const currifiedModifyByIds = (status) => () => (
    dispatch(modifyByIds([props.id], status))
  );

  return {
    markAsRead: currifiedModifyByIds('READ'),
    fetchMessage: () => { dispatch(fetchMessage(props.id)); },
    toggleExpanded: () => { dispatch(toggleExpanded(props.id)); },
    toggleSelected: () => { dispatch(toggleSelected(props.id)); },
    remove: currifiedModifyByIds('DELETED'),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageContainer);
