import { useEffect } from 'react';
import PropTypes from 'prop-types';

import subscribe from './services/push_subscription';
import ModalView from './modal_view';

const ModalContainer = ({ fetchMessages, prependMessage, reset }) => {
  useEffect(() => {
    let unSubscribe;

    fetchMessages().then(() => {
      unSubscribe = subscribe(prependMessage);
    });

    return () => {
      if (unSubscribe) unSubscribe();
      reset();
    };
  }, []);

  return <ModalView />;
};

ModalContainer.propTypes = {
  fetchMessages: PropTypes.func.isRequired,
  prependMessage: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default ModalContainer;
