import PropTypes from 'prop-types';
import { Card, Icon, Button } from 'bv-components';
import classnames from 'classnames';

const MessageView = ({
  onMessageClick,
  isRead,
  selected,
  deliveryDate,
  subject,
  cta,
  editing,
  body,
  remove,
  onCtaClick,
}) => {
  const className = classnames('ci-message', {
    read: isRead,
    editing,
    selected,
  });

  return (
    <li onClick={onMessageClick}>
      <Card className={className}>
        <div className="ci-message-text">
          <div className="ci-message__date">
            {deliveryDate}
          </div>
          <div className="ci-message__title">
            {editing && (
              <div className="ci-message__select bvs-link">
                {selected && <Icon id="check" />}
              </div>
            )}
            {subject}
          </div>
          <div className="ci-message__body">
            {body}
          </div>
          <Icon
            className="ci-message__remove bvs-link"
            id="trash"
            onClick={remove}
            type="user-inbox-remove"
          />
        </div>
        {cta && (
          <div className="ci-message__cta">
            <Button
              noClass
              type="user-inbox-msg-cta"
              onClick={onCtaClick}
            >
              {cta.name}
            </Button>
          </div>
        )}
      </Card>
    </li>
  );
};

MessageView.propTypes = {
  onMessageClick: PropTypes.func.isRequired,
  isRead: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  deliveryDate: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  cta: PropTypes.instanceOf(Object).isRequired,
  editing: PropTypes.bool.isRequired,
  body: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  onCtaClick: PropTypes.func.isRequired,
};

export default MessageView;
