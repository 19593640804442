import { ModalWindow } from 'bv-components';
import {
  HeaderLeft, HeaderTitle, Messages, EditButtons,
} from './components';

const ModalView = () => (
  <ModalWindow className="user-inbox-modal" renderTitle={() => <HeaderTitle />} renderLeft={() => <HeaderLeft />}>
    <Messages />
    <EditButtons />
  </ModalWindow>
);

export default ModalView;
