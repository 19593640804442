import { createSelector } from 'reselect';
import { property, compose } from 'underscore';

const getSubState = property('userInbox');
export const getMessages = compose(property('messages'), getSubState);
export const getEditing = compose(property('editing'), getSubState);

export const getSelectedMessages = createSelector(
  getMessages,
  (messages) => messages.filter(property('selected')),
);

export const getHasMessages = createSelector(
  getMessages,
  (messages) => messages.length > 0,
);
