/* eslint no-undef: off */
import { useEffect, useRef, useCallback } from 'react';
import { useLockBodyScroll } from 'bv-hooks';
import PropTypes from 'prop-types';
import { throttle, property } from 'underscore';
import classnames from 'classnames';

import Message from '../message';
import Spinner from './spinner';

const MessagesView = ({
  messages,
  editing,
  fetchNextPage,
  fetching,
}) => {
  const listRef = useRef(null);

  const checkScroll = useCallback(throttle(() => {
    const list = listRef.current;

    if (list.clientHeight + list.scrollTop >= list.scrollHeight - 100) {
      fetchNextPage();
    }
  }, 300), []);

  // Effect to check scroll when the list changes
  useEffect(
    () => { checkScroll(); },
    messages.map(property('id')),
  );

  // Effect to disable/enable the body scroll, it will allow the scroll in the list
  useLockBodyScroll(listRef);

  return (
    <ul className={classnames('ci-messages has-scroll-bars', { editing })} ref={listRef} onScroll={checkScroll}>
      {messages.map((message) => <Message key={message.id} {...message} />)}
      {fetching && <Spinner />}
    </ul>
  );
};

MessagesView.propTypes = {
  fetching: PropTypes.bool.isRequired,
  messages: PropTypes.instanceOf(Array).isRequired,
  editing: PropTypes.bool.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
};

export default MessagesView;
