import { connect } from 'react-redux';
import { compose } from 'underscore';

import { fetchNextPage } from 'UserInboxModal/duck';
import { getEditing } from 'UserInboxModal/selectors';
import MessagesContainer from './messages_container';

const mapStateToProps = (state) => ({
  fetching: state.userInbox.fetching,
  messages: state.userInbox.messages,
  editing: getEditing(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchNextPage: compose(dispatch, fetchNextPage),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessagesContainer);
