import { registerHandler, unregisterHandler } from 'event-bus';
import { accountNumber } from 'bv-helpers/session';
import decorate from 'UserInboxModal/decorators/message';

const eventBusAddress = `/user/customerinbox/newMessage/${accountNumber()}`;

export default (onMessage) => {
  const handler = (err, { body: { message } }) => {
    onMessage(decorate(message));
  };

  registerHandler(eventBusAddress, handler);

  return () => { unregisterHandler(eventBusAddress, handler); };
};
