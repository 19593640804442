import PropTypes from 'prop-types';
import { t } from 'bv-api';

const HeaderView = ({
  editing,
  toggleEditing,
  hasMessages,
}) => (
  hasMessages ? (
    <div className="ci-header__left" onClick={toggleEditing}>
      {t(`customer_inbox.${editing ? 'cancel' : 'edit'}`)}
    </div>
  ) : null
);

HeaderView.propTypes = {
  editing: PropTypes.bool.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  hasMessages: PropTypes.bool.isRequired,
};

export default HeaderView;
