import PropTypes from 'prop-types';
import { handleCta } from 'bv-services';
import MessageView from './message_view';

const MessageContainer = ({
  markAsRead,
  status,
  editing,
  toggleSelected,
  toggleExpanded,
  preview,
  text,
  fetchMessage,
  expanded,
  cta,
  ...rest
}) => {
  const markAsReadIfNeeded = () => {
    if (status === 'UNREAD') markAsRead();
  };

  const onMessageClick = () => {
    if (editing) toggleSelected();
    else {
      markAsReadIfNeeded();

      if (!text) fetchMessage();
      toggleExpanded();
    }
  };

  const onCtaClick = (event) => {
    event.stopPropagation();

    markAsReadIfNeeded();
    handleCta(cta);
  };

  return (
    <MessageView
      onRead={markAsReadIfNeeded}
      onMessageClick={onMessageClick}
      body={(expanded && text) || preview}
      isRead={status === 'READ'}
      onCtaClick={onCtaClick}
      cta={cta}
      editing={editing}
      {...rest}
    />
  );
};

MessageContainer.propTypes = {
  markAsRead: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  preview: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  fetchMessage: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  cta: PropTypes.instanceOf(Object).isRequired,
};

export default MessageContainer;
