import PropTypes from 'prop-types';
import { property } from 'underscore';
import EditButtonsView from './edit_buttons_view';

const EditButtonsContainer = ({
  editing,
  messages,
  selected,
  selectAll,
  unselectAll,
  markAsRead,
  markAsUnread,
  remove,
}) => {
  if (!editing || !messages.length) return null;

  const allSelected = messages.every(property('selected'));
  const someRead = selected.some((message) => message.status === 'READ');
  const someUnread = selected.some((message) => message.status === 'UNREAD');
  const someSelected = selected.length > 0;

  const buttons = [{
    onClick: allSelected ? unselectAll : selectAll,
    tKey: allSelected ? 'deselect_all' : 'select_all',
    active: true,
  }, {
    onClick: markAsRead,
    tKey: 'mark_as_read',
    active: someUnread,
  }, {
    onClick: markAsUnread,
    tKey: 'mark_as_unread',
    active: someRead,
  }, {
    onClick: remove,
    active: someSelected,
    icon: { id: 'trash', type: 'user-inbox-trash' },
  }];

  return <EditButtonsView buttons={buttons} />;
};

EditButtonsContainer.propTypes = {
  editing: PropTypes.bool.isRequired,
  messages: PropTypes.instanceOf(Array).isRequired,
  selected: PropTypes.instanceOf(Array).isRequired,
  selectAll: PropTypes.func.isRequired,
  unselectAll: PropTypes.func.isRequired,
  markAsRead: PropTypes.func.isRequired,
  markAsUnread: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};

export default EditButtonsContainer;
