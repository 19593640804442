import { connect } from 'react-redux';
import { compose } from 'underscore';

import userInbox, { fetchMessages, prependMessage, reset } from './duck';
import ModalContainer from './modal_container';

const { addReducers } = window.reduxState;

addReducers({
  userInbox,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMessages: compose(dispatch, fetchMessages),
  prependMessage: compose(dispatch, prependMessage),
  reset: compose(dispatch, reset),
});

export default connect(null, mapDispatchToProps)(ModalContainer);
