import PropTypes from 'prop-types';
import { t } from 'bv-api';
import { Icon } from 'bv-components';

const EditButtonsView = ({ buttons }) => (
  <ul className="ci-edit-buttons">
    {buttons.map(({
      onClick,
      tKey,
      icon,
      active,
    }) => (
      <li key={tKey || icon?.id} onClick={onClick} className={active ? 'bvs-link' : 'disabled bvs-link'}>
        {tKey && t(`customer_inbox.${tKey}`)}
        {icon && <Icon {...icon} />}
      </li>
    ))}
  </ul>
);

EditButtonsView.propTypes = {
  buttons: PropTypes.instanceOf(Array).isRequired,
};

export default EditButtonsView;
