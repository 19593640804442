import { connect } from 'react-redux';
import { getSelectedMessages, getEditing, getMessages } from 'UserInboxModal/selectors';

import {
  setSelected,
  modifySelected,
} from 'UserInboxModal/duck';

import EditButtonsContainer from './edit_buttons_container';

const mapStateToProps = (state) => ({
  messages: getMessages(state),
  selected: getSelectedMessages(state),
  editing: getEditing(state),
});

const mapDispatchToProps = (dispatch) => {
  const currifiedModifySelected = (status) => () => (
    dispatch(modifySelected(status))
  );

  return {
    selectAll: () => dispatch(setSelected(true)),
    unselectAll: () => dispatch(setSelected(false)),
    markAsRead: currifiedModifySelected('READ'),
    markAsUnread: currifiedModifySelected('UNREAD'),
    remove: currifiedModifySelected('DELETED'),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditButtonsContainer);
