import { compose, property, uniq } from 'underscore';

import {
  fetchMessages as doFetchMessages,
  fetchMessage as doFetchMessage,
  changeStatus,
} from './api';

import { getSelectedMessages } from './selectors';

// ============
// Action types
// ============
const FETCH_MESSAGES_INIT = 'userInbox/FETCH_MESSAGES_INIT';
const FETCH_MESSAGES_SUCCESS = 'userInbox/FETCH_MESSAGES_SUCCESS';
const TOGGLE_EDITING = 'userInbox/TOGGLE_EDITING';
const TOGGLE_SELECTED = 'userInbox/TOGGLE_SELECTED';
const SET_SELECTED = 'userInbox/SET_SELECTED';
const MODIFY_STATUS_BY_IDS = 'userInbox/MODIFY_STATUS_BY_IDS';
const REMOVE_BY_IDS = 'userInbox/REMOVE_BY_IDS';
const FETCH_MESSAGE_SUCCESS = 'userInbox/FETCH_MESSAGE_SUCCESS';
const TOGGLE_EXPANDED = 'userInbox/TOGGLE_EXPANDED';
const PREPEND_MESSAGE = 'userInbox/PREPEND_MESSAGE';
const RESET = 'userInbox/RESET';

// ===============
// Action creators
// ===============
const fetchMessagesInit = () => ({
  type: FETCH_MESSAGES_INIT,
});

const fetchMessagesSuccess = ({ messages }) => ({
  type: FETCH_MESSAGES_SUCCESS,
  messages,
});

export const toggleEditing = () => ({
  type: TOGGLE_EDITING,
});

export const toggleSelected = (id) => ({
  type: TOGGLE_SELECTED,
  id,
});

export const setSelected = (selected) => ({
  type: SET_SELECTED,
  selected,
});

const modifyStatusByIds = (ids, status) => ({
  type: MODIFY_STATUS_BY_IDS,
  ids,
  status,
});

const removeByIds = (ids) => ({
  type: REMOVE_BY_IDS,
  ids,
});

const fetchMessageSuccess = (id) => ({ text }) => ({
  type: FETCH_MESSAGE_SUCCESS,
  id,
  text,
});

export const toggleExpanded = (id) => ({
  type: TOGGLE_EXPANDED,
  id,
});

export const prependMessage = (message) => ({
  type: PREPEND_MESSAGE,
  message,
});

export const reset = () => ({
  type: RESET,
});

// ======
// Thunks
// ======
export const fetchMessages = (page = 0) => (dispatch) => {
  dispatch(fetchMessagesInit());

  return doFetchMessages(page).then(compose(dispatch, fetchMessagesSuccess));
};

export const modifyByIds = (ids, status) => (dispatch) => (
  changeStatus(ids, { status })
    .then(() => dispatch(
      status === 'DELETED'
        ? removeByIds(ids)
        : modifyStatusByIds(ids, status),
    ))
    .then(() => dispatch(setSelected(false)))
);

export const modifySelected = (status) => (dispatch, getState) => (
  dispatch(modifyByIds(
    getSelectedMessages(getState()).map(property('id')),
    status,
  ))
);

export const fetchMessage = (id) => (dispatch) => (
  doFetchMessage(id).then(compose(dispatch, fetchMessageSuccess(id)))
);

const pageSize = 5;

export const fetchNextPage = () => (dispatch, getState) => {
  const { userInbox: { messages, fetching, searchMore } } = getState();

  if (!fetching && searchMore) {
    dispatch(fetchMessages(Math.floor(messages.length / pageSize)));
  }
};

// =======
// Reducer
// =======
const initialState = {
  messages: [],
  fetching: true,
  editing: false,
  searchMore: true,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_MESSAGES_INIT:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_MESSAGES_SUCCESS: {
      return {
        ...state,
        fetching: false,
        messages: uniq([
          ...state.messages,
          ...action.messages,
        ], property('id')),
        searchMore: action.messages.length >= pageSize,
      };
    }
    case TOGGLE_EDITING:
      return {
        ...state,
        editing: !state.editing,
      };
    case TOGGLE_SELECTED:
      return {
        ...state,
        messages: state.messages.map((message) => (
          action.id === message.id ? { ...message, selected: !message.selected } : message
        )),
      };
    case SET_SELECTED:
      return {
        ...state,
        messages: state.messages.map((message) => ({
          ...message,
          selected: action.selected,
        })),
      };
    case MODIFY_STATUS_BY_IDS:
      return {
        ...state,
        messages: state.messages.map((message) => (
          action.ids.includes(message.id) ? { ...message, status: action.status } : message
        )),
      };
    case REMOVE_BY_IDS:
      return {
        ...state,
        messages: state.messages.filter((message) => !action.ids.includes(message.id)),
      };
    case FETCH_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: state.messages.map((message) => (
          action.id === message.id ? { ...message, text: action.text } : message
        )),
      };
    case TOGGLE_EXPANDED:
      return {
        ...state,
        messages: state.messages.map((message) => (
          message.id === action.id ? { ...message, expanded: !message.expanded } : message
        )),
      };
    case PREPEND_MESSAGE:
      return {
        ...state,
        messages: [
          action.message,
          ...state.messages,
        ],
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};
