import { Provider } from 'react-redux';
import ModalRedux from './modal_redux';

const { store } = window.reduxState;

const UserInboxApp = () => (
  <Provider store={store}>
    <ModalRedux />
  </Provider>
);

export default UserInboxApp;
