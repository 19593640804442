import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';
import MessagesView from './messages_view';
import NoMessagesView from './no_messages_view';

const MessagesContainer = ({
  fetching,
  messages,
  ...rest
}) => {
  if (!messages.length) {
    if (fetching) return <Spinner />;
    return <NoMessagesView />;
  }

  return (
    <MessagesView
      messages={messages}
      fetching={fetching}
      {...rest}
    />
  );
};

MessagesContainer.propTypes = {
  fetching: PropTypes.bool.isRequired,
  messages: PropTypes.instanceOf(Array).isRequired,
};

export default MessagesContainer;
