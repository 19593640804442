import { connect } from 'react-redux';
import { compose } from 'underscore';

import { getEditing, getHasMessages } from 'UserInboxModal/selectors';
import { toggleEditing } from 'UserInboxModal/duck';
import HeaderView from './header_view';

const mapStateToProps = (state) => ({
  editing: getEditing(state),
  hasMessages: getHasMessages(state),
});

const mapDispatchToProps = (dispatch) => ({
  toggleEditing: compose(dispatch, toggleEditing),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderView);
