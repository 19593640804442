import { getJSON } from 'bv-fetch';
import decorate from 'UserInboxModal/decorators/message';

export default (page) => (
  getJSON('/bv_api/customer_inbox', { page })
    .then((response) => ({
      ...response,
      messages: response.messages.map(decorate),
    }))
);
